<template>
  <div id="main">
    <hsc-window-style-black>

        <div id="video-container" class="video-contain" style="margin-left: auto; margin-right: auto;">
          <video-player v-if="$store.state.videoPlayer.video.src" ref="vp" class="vjsplayer" :options="videoOptions" :fullscreen="true"/>
        </div>

        <hsc-window :width="600" :height="250" id="timeline-chart-id" :resizable="true" @resize-end="triggerResize()" class="chart timeline-chart" :class="{elhidden: displayTimeline === false || $store.state.videoPlayer.video.isOriginalPlayed}" ref="win6" title="Events and Behaviors" positionHint="10 / 90" :closeButton="true" :isOpen.sync="$store.state.events.panels.showTimeline" >
            <timeline-chart class="chart-component" style="height: 100%;"
                            v-if="$store.state.analyzedVideos.analyzedVideoBehaviour && $store.state.analyzedVideos.analyzedVideoBehaviour.events
                                   && $store.state.analyzedVideos.analyzedVideoBehaviour.behavior && existAtLeastOneBehavior()"
                            ></timeline-chart>
            <div class="no-data full-width-height" v-if="!$store.state.analyzedVideos.analyzedVideoBehaviour || ($store.state.analyzedVideos.analyzedVideoBehaviour && (!$store.state.analyzedVideos.analyzedVideoBehaviour.events || !$store.state.analyzedVideos.analyzedVideoBehaviour.behavior || !existAtLeastOneBehavior()))">
                No Events and Behaviors data available
            </div>
        </hsc-window>
        <hsc-window id="line-chart-wrapper" :height="250" class="chart line-chart" :class="{elhidden: displayLineChart === false  || $store.state.videoPlayer.video.isOriginalPlayed}" ref="win5" title="Object Detection Graph" positionHint="-80 / 90" :closeButton="true" :isOpen.sync="$store.state.events.panels.showLineChart" >
            <line-chart v-if="$store.state.linechart.values && $store.state.linechart.values.length > 0" v-bind:chartHeight="200" v-bind:chartWidth="600" class="chart-component" ref="chartRef" style="height: 100%;"></line-chart>
            <div class="no-data" v-if="!$store.state.linechart.values || ($store.state.linechart.values && $store.state.linechart.values.length === 0)">
                No Object Detection data available
            </div>
        </hsc-window>
        <hsc-window id="similarity-panel-id" class="chart timeline-chart" :class="{elhidden: displaySimilarityPanel === false || $store.state.videoPlayer.video.isOriginalPlayed}" ref="win5" title="Similar Workspaces" positionHint="center" :closeButton="true" :isOpen.sync="$store.state.events.panels.showSimilarityPanel" >
            <similarity-panel v-if="$store.state.similarity.data && $store.state.similarity.data.scores && $store.state.similarity.data.scores.length > 0" ref="similarityRef" style="height: 100%;"></similarity-panel>
            <div class="no-data" v-if="!$store.state.similarity.data || ($store.state.similarity.data && (!$store.state.similarity.data.scores || $store.state.similarity.data.scores && $store.state.similarity.data.scores.length === 0))">
                No Similarity data available
            </div>
        </hsc-window>
        <hsc-window id="anomaly-panel-id" style="width: 600px; height: 150px;" class="chart timeline-chart" :class="{elhidden: displayAnomalyPanel === false || $store.state.videoPlayer.video.isOriginalPlayed}" ref="win3" title="Anomaly Detection" positionHint="20 / -20" :closeButton="true" :isOpen.sync="$store.state.events.panels.showAnomalyPanel" >
            <anomaly-panel v-if="$store.state.anomaly.data && $store.state.anomaly.data.analyzeId" ref="anomalyRef" style="height: 100%;"></anomaly-panel>
            <div class="no-data full-width-height" v-if="!$store.state.anomaly.data || !$store.state.anomaly.data.analyzeId ">
                No Anomaly data available
            </div>
        </hsc-window>

    </hsc-window-style-black>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css';
import VideoPlayer from "@/components/VideoPlayer.vue";
import SimilarityPanel from "@/components/SimilarityPanel.vue";
import AnomalyPanel from "@/components/AnomalyPanel.vue";
import LineChart from "@/components/linechart.vue";
import TimelineChart from "@/components/TimelineChart.vue";
export default {
  name: 'Home',
  components: {
    VideoPlayer,
    SimilarityPanel,
    AnomalyPanel,
    LineChart,
    TimelineChart
  },
  data() {
    return {
      displayPlayer: false,
      values: [],
      duration: 0,
      videoOptions: {
        width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
        height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
        poster: '../../assets/images/earth_background.png',
        autoplay: 'muted',
        controls: true,
        muted: true,
        preload: 'auto',
        fluid: false
      },
      windowResizeEvent: null
    }
  },
  computed: {
    displayLineChart() {
      return this.$store.state.events.panels.showLineChart;
    },
    displayTimeline() {
      return this.$store.state.events.panels.showTimeline;
    },
    displaySimilarityPanel() {
      return this.$store.state.events.panels.showSimilarityPanel;
    },
    displayAnomalyPanel() {
      return this.$store.state.events.panels.showAnomalyPanel;
    },
  },
    watch: {
        "$store.state.videoPlayer.video.src": {
            deep: true,
            handler: function (newVal, oldVal) {
                if(newVal){
                    setTimeout(() => {
                        if(this.$refs.vp){
                            this.windowResizeEvent = this.resizeVideoContainer.bind(this);
                            window.addEventListener('resize', this.windowResizeEvent);
                            this.$refs.vp.player.on('resize', this.resizeVideoContainer);
                        }
                    })

                } else {
                    if(this.$refs.vp) {
                        this.$refs.vp.player.off('resize');
                    }
                    window.removeEventListener('resize', this.windowResizeEvent);
                    this.windowResizeEvent = null;
                }
            }
        },
    },

  methods: {
    triggerResize() {
      this.$store.commit('SET_RESIZE_TIMELINE', true);
    },
    resizeVideoContainer() {
      if(this.$refs.vp && this.$refs.vp.player){
          let cw = this.$refs.vp.player.videoWidth();
          let ch = this.$refs.vp.player.videoHeight();
          let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
          let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

          let diffWidth = (vw / cw);
          let diffHeight = (vh / ch);

          // if dH and dW are both greater than 1, take the smaller of the two diffs
          // and resize to viewport based on that dimension
          if ((diffHeight > 1 && diffWidth > 1) || (diffHeight < 1 && diffWidth < 1)) {
              if (diffHeight < diffWidth) {
                  this.resizeByHeight(vh);
              }
              else {
                  this.resizeByWidth(vw);
              }
          }
          else if (diffWidth >= 1 && diffHeight < 1) {
              this.resizeByHeight(vh);
          }
          else if (diffHeight >= 1 && diffWidth < 1) {
              this.resizeByWidth(vw);
          }
      }

    },
    resizeByHeight(videoHeight) {
      let newWidth = this.calculateProportionalWidth(videoHeight);
      if(typeof newWidth === 'number'  && !isNaN(newWidth)){
          this.$refs.vp.player.height(videoHeight);
          this.$refs.vp.player.width(newWidth);
          document.getElementById('video-container').style.height = videoHeight + 'px';
          document.getElementById('video-container').style.width = newWidth + 'px';
      }

    },
    resizeByWidth(videoWidth) {
      let newHeight = this.calculateProportionalHeight(videoWidth);
      if(typeof newHeight === 'number' && !isNaN(newHeight)){
          this.$refs.vp.player.width(videoWidth);
          this.$refs.vp.player.height(newHeight);
          document.getElementById('video-container').style.width = videoWidth + 'px';
          document.getElementById('video-container').style.height = newHeight + 'px';
      }

    },
    calculateProportionalWidth(newHeight) {
      let vidH = this.$refs.vp.player.videoHeight();
      let vidW = this.$refs.vp.player.videoWidth();
      let newWidth = newHeight * (vidW/vidH);
      return Math.floor(newWidth);
    },
    calculateProportionalHeight(newWidth) {
      let vidH = this.$refs.vp.player.videoHeight();
      let vidW = this.$refs.vp.player.videoWidth();
      let newHeight = newWidth * (vidH/vidW);
      return Math.floor(newHeight);
    },
    existAtLeastOneBehavior() {
        let behaviors = this.$store.state.analyzedVideos.analyzedVideoBehaviour.behavior;
        return !!Object.keys(behaviors).find(key => behaviors[key].length > 0);
    }
  },
  beforeDestroy() {
      window.removeEventListener('resize', this.windowResizeEvent);
      this.windowResizeEvent = null;
      if(this.$refs.vp) {
          this.$refs.vp.player.off('resize');
      }
  },

  mounted() {
    this.$store.commit('SHOW_HEADER', true);

    if (this.$router.currentRoute.query.pipelineId) {
      let payload = {
          id: this.$router.currentRoute.query.pipelineId
      };
        this.$store.dispatch('getPipelineConfig', payload).then(() => {
            this.$store.commit('TOGGLE_PARTIAL_VIDEO_EVENT', false);
            this.$store.dispatch('reloadAllVideoAnalytics', this.$router.currentRoute.query.analyzeId);
        });

    } else {
        if(this.$store.state.videoPlayer.video && this.$store.state.videoPlayer.video.id && this.$store.state.videoPlayer.video.analyzeId) {
            this.$router.replace({
                query: {
                    pipelineId: this.$store.state.videoPlayer.video.id,
                    analyzeId: this.$store.state.videoPlayer.video.analyzeId
                }
            })
        }
    }

      if(this.$refs.vp){
          this.windowResizeEvent = this.resizeVideoContainer.bind(this);
          window.addEventListener('resize', this.windowResizeEvent);
          this.$refs.vp.player.on('resize', this.resizeVideoContainer);
      }
  }
}
</script>

<style lang="scss">
.line-chart {
    background-color: rgba(57, 60, 67, 0.6) !important;
    max-width: 650px;
    z-index: 1000!important;
    .no-data {
        height: 200px;
        width: 350px;
    }
}

.timeline-chart{
    background-color: rgba(57, 60, 67, 0.6) !important;
    z-index: 1000 !important;

    .no-data {
        height: 200px;
        width: 350px;
    }
    .full-width-height {
        height: 100%!important;
        width: 100% !important;
    }

}
.chart {
  /*background-color: rgba(136, 175, 212, 0.9);*/
  .content {
    background-color: inherit;
    .chart-component {
    }
  }
  .titlebar{
      background-color: rgba(57, 60, 67, 0.7) !important;
  }
}
#main {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('../../assets/images/earth_background.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
}
#body-wrapper {
  flex-grow: 1;
  display: flex;
}
.vjsmap {
  width: 175px;
  float: right;
  margin-right: 30px;
}
.vjsmodal {
  border: 1px solid #fff;
  width: 30px;
  height: 30px;
  margin-top: 105px;
}
.elhidden {
  visibility: hidden !important;
}
.content {
  opacity: 0.5;
  background-color: #fff;
  padding: 0px !important;
}

.titlebar {
  color: #e2e5df;
  background-color: #3a76b2 !important;
  font-size: 12px;
  padding: 3px !important;
  padding-top: 6px !important;
  padding-left: 6px !important;
}
.window {
  border-radius: 0px !important;
  color: white;
  boxShadow: 0 2pt 8pt rgba(0, 0, 0, 0.7);
}
.video-contain {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
    .no-data {
        height: 100%;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
    }

</style>
