<template>
    <div>
        <video ref="videoPlayer" class="video-js" :class="{ 'vjs-big-play-centered': fullscreen == true }"></video>
    </div>
</template>

<script>
import videojs from 'video.js';
export default {
    name: "VideoPlayer",
    props: {
      fullscreen: {
        type: Boolean,
        default: true
      },
      options: {
        type: Object,
        controls: true,
        preload: 'metadata',
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        player: null
      }
    },
    computed: {
      videoCurrentTime() {
        return this.$store.state.videoPlayer.newVideoCurrentTime;
      },
      externalStopVideo() {
          return this.$store.state.videoPlayer.externalIsPlaying;
      }
    },
    watch: {
      externalStopVideo(newVal) {
          if(newVal) {
              if (this.player) {
                  if (!this.player.isDisposed()) {
                      this.player.play();
                  }
              }
          } else {
              if (newVal === false && this.player) {
                  if (!this.player.isDisposed()) {
                      this.player.pause();
                  }
              }
          }
          this.$store.commit('PAUSE_VIDEO_EXTERNAL', null)
      },
    "$store.state.videoPlayer.video.src": {
        deep: true,
        handler: function (newVal, oldVal) {
            if (this.player) {
                if (!this.player.isDisposed()) {
                    if(newVal){
                        if (!this.$store.state.azureBlobData.data.sas.key || this.$store.state.videoPlayer.video.container !== this.$store.state.azureBlobData.data.sas.container) {
                            this.$store.dispatch('getSharedAccessSignature', this.$store.state.videoPlayer.video.container).then(() => {
                                this.setVideoSourceAndType();
                            });
                        } else {
                            this.setVideoSourceAndType();
                        }
                    }

                }
            }
        }
    },

    videoCurrentTime(newVal, oldVal) {
        if (this.player && newVal !== null) {
          if (!this.player.isDisposed()) {
            this.player.currentTime(newVal);
          }
        }
      }
    },
    mounted() {
      this.initPlayer();
      this.ModalDialog = videojs.getComponent('ModalDialog');
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose()
        }
    },
    methods: {
        initPlayer() {
            this.player = videojs(this.$refs.videoPlayer, this.options, ready => {

                this.player.on('playing', this.onPlaying);
                this.player.on('pause', this.onPause);
                this.player.on('waiting', this.onPause);
                this.player.on('loadedmetadata', this.onLoadedMetadata)
                this.player.on('error', () => {
                    const error = this.player.error();
                    this.$store.commit("SHOW_MESSAGE", {
                        type: 1,
                        message: error.message,
                        error: new Error(error.message)
                    });
                });
                if(this.player){
                    this.player.autoplay(false);
                }

                if(this.$store.state.videoPlayer.video.src) {
                    if(!this.$store.state.azureBlobData.data.sas.key || this.$store.state.videoPlayer.video.container !== this.$store.state.azureBlobData.data.sas.container) {
                        this.$store.dispatch('getSharedAccessSignature', this.$store.state.videoPlayer.video.container).then(() => {
                            this.setVideoSourceAndType();
                        })
                    } else {
                        this.setVideoSourceAndType();
                    }
                }
                videojs.Hls.xhr.beforeRequest = (options) => {
                    if(process.env.VUE_APP_BASE_URL === '/nash/') {
                        if(!this.$store.state.azureBlobData.data.sas.key || this.$store.state.videoPlayer.video.container !== this.$store.state.azureBlobData.data.sas.container) {
                            this.$store.dispatch('getSharedAccessSignature', this.$store.state.videoPlayer.video.container).then(() => {
                                options.uri = options.uri + '?' + this.$store.state.azureBlobData.data.sas.key;
                            })
                        } else {
                            options.uri = options.uri + '?' + this.$store.state.azureBlobData.data.sas.key;
                        }
                    }
                };
            });
        },
        setVideoSourceAndType() {
            const blobDetails = this.$store.state.azureBlobData.data.blobDetails;
            const videoPrefix = `https://${blobDetails.storageName}.blob.core.windows.net/${this.$store.state.videoPlayer.video.container}/`;
            const videoSrc = process.env.VUE_APP_BASE_URL === '/nash/' ? `${videoPrefix}${this.$store.state.videoPlayer.video.src}` : `/assets/video/${this.$store.state.videoPlayer.video.src}`;
            let type = 'video/mp4';
            if( this.$store.state.videoPlayer.video.isOriginalPlayed) {
                this.setSasIfNeededAndUpdateSource(videoPrefix, process.env.VUE_APP_BASE_URL === '/nash/' ? `${videoSrc}?${this.$store.state.azureBlobData.data.sas.key}` : videoSrc, type);
            } else {
                type = 'application/x-mpegURL';
                this.setSasIfNeededAndUpdateSource(videoPrefix, videoSrc, type);
            }

        },
        setSasIfNeededAndUpdateSource(videoPrefix, videoSrc, type) {
            const poster = this.$store.state.videoPlayer.video.thumbnailUrl;
            if(poster) {
                this.player.poster(`${videoPrefix}${poster}?${this.$store.state.azureBlobData.data.sas.key}`);
            }
            if (this.$store.state.videoPlayer.video.src) {
                this.playerSetSrc(videoSrc);
                this.playerSetContentType(type)
            }
        },
        playerSetContentType(type) {
          this.player.contentType = type;
        },
        playerSetSrc(url) {
          this.player.src(url);
        },
        onPlaying($event) {
         if (!this.$store.state.videoPlayer.video.isOriginalPlayed) {
            this.$store.commit("PLAY_VIDEO", this.player.currentTime())
          }
        },
        onPause($event) {
          if (!this.$store.state.videoPlayer.video.isOriginalPlayed) {
            this.$store.commit("PAUSE_VIDEO", this.player.currentTime())
          }
        },
        onLoadedMetadata($event) {
            if (!this.$store.state.videoPlayer.video.isOriginalPlayed) {
              const playerMetadataDuration = this.player.duration();
              const serverMetadataDuration = this.$store.state.videoPlayer.totalVideoDuration;

              // Safety check -  player metadata can have wrong duration sometimes
              // ( issue was found on a video that was on fast-forward, so that could be the issue)
              // If playerMetadataDuration is larger than server-metadata Duration
              // we'll use server metadata duration. We cannot relay always on server-metadata duration,
              // as server always gives us full video duration, and there are moments when we show only parts
              // of video
              if ((playerMetadataDuration - serverMetadataDuration) > 1) {
                this.player.duration(serverMetadataDuration);
              }

              this.$store.commit("SET_VIDEO_DURATION", this.player.duration())
            }
        }

    }
}
</script>

<style scoped>
video {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    min-height: 50%;
    min-width: 50%;
}
</style>
