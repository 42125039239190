<template>
    <div id="myPlot" style="width: 100%; height: 100%"></div>
</template>

<script>
  import TimelinesChart from '@/assets/lib/timelines-chart/dist/timelines-chart.js';
  import * as d3 from "d3";
  import * as moment from 'moment'
  export default {
    name: "timelinechart",
    data() {
        return {
            chart: null,
            chartData: null,
            listener: null,
            initiated: false,
        }
    },
    mounted() {
        this.initChart();
    },
    computed: {
      data() {
        return this.$store.state.analyzedVideos.analyzedVideoBehaviour;
      },
      resizeEvent() {
        return this.$store.state.events.charts.timelineChart.resize;
      },
      showTimeline() {
          return this.$store.state.events.panels.showTimeline;
      },
    },
    watch: {
      resizeEvent(newVal, oldVal) {
        if (newVal !== false) {
            if(this.chart && this.showTimeline && this.initiated){
                this.chart.width(document.getElementById('timeline-chart-id').offsetWidth);
            }
            if(!this.chart) {
                if(this.showTimeline){
                    this.initChart();
                }
            }
          this.$store.commit('SET_RESIZE_TIMELINE', false);
        }
      },
      data(val) {
          if(this.showTimeline){
              if(this.initiated)
                this.redrawChart();
              else this.initChart()
          }
      },
      showTimeline(val) {
        if(val) {
            if(!this.initiated)
              this.initChart();
            else this.redrawChart()
        }
      }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {
        redrawChart() {
            let data = this.$store.state.analyzedVideos.analyzedVideoBehaviour;
            if(data && this.chart && (data.events  || data.behavior )){
                this.chartData = this.buildChartData(data);
                if(this.chartData.length > 0){
                    this.chart.data(this.chartData);
                    this.chart.refresh();
                }

            }
        },
        initChart() {
            let data = this.$store.state.analyzedVideos.analyzedVideoBehaviour;
            let colorScale = d3.scaleOrdinal([
                "Oscillation", "Peak", "Valley", "Surge", "Dive",
                'Traffic increase event', 'Traffic decrease event' , 'Traffic surge event','Traffic dive event'],
                ["#F450C3","#9D50EF", "#6356EF", "#FB7C4A", "#FF526F", "#00B067", "#F7B851", "#00B067" , "#F7B851"]);

            if(this.showTimeline && (data.events || data.behavior )){
                this.chartData = this.buildChartData(data);
                this.initiated = true;
                this.chart = TimelinesChart();
                this.chart
                    .segmentTooltipContent(this.formatTooltip)
                    .xTickFormat(this.formatX)
                    .maxHeight(200)
                    .width(document.getElementById('timeline-chart-id').offsetWidth)
                    .maxLineHeight(13)
                    .rightMargin(25)
                    .onZoom((zoom) => this.onSelectionChange(zoom))
                    .onSegmentClick(this.onSegmentClick)
                    .zQualitative(false)
                    .zColorScale(colorScale);
                if(this.chartData.length > 0){
                    this.chart.data(this.chartData)(document.getElementById('myPlot'));
                    this.chart.width(document.getElementById('myPlot').offsetWidth);
                    Array.from(document.getElementsByClassName('legendG')).forEach(a => {
                        a.remove();
                    });
                }

                setTimeout(() => {
                    this.listener = () => {
                        this.chart.width(document.getElementById('timeline-chart-id').offsetWidth)
                    };
                    window.addEventListener('resize', this.handleResize);
                    d3.selectAll('.series-group')
                    .on('click', () => {
                        this.$store.commit("SET_TIMELINE_BEHAVIOR_SELECTION", null);
                    });
                    this.handleResize();
                }, 500);

            }
        },
        formatX(timestamp) {
          let secs = timestamp.getSeconds();
          if (secs < 10) { secs = '0' + secs; }
          return timestamp.getMinutes() + ':' + secs;
        },
        formatTooltipTime(timestamp) {
          let mins = timestamp.getMinutes();
          if (mins < 10) { mins = '0' + mins; }
          let secs = timestamp.getSeconds();
          if (secs < 10) { secs = '0' + secs; }
          let ms = timestamp.getMilliseconds();
          ms = Math.ceil(ms / 10);
          return  mins + ':' + secs + ':' + ms;
        },
        formatTooltip(data) {
          return '<strong>' + data.val + '<br/>From:</strong> ' + this.formatTooltipTime(data.timeRange[0]) + '<br/><strong>To:</strong> ' + this.formatTooltipTime(data.timeRange[1]);
        },
        handleResize() {
            if(this.showTimeline){
                this.chart.width(document.getElementById('timeline-chart-id').offsetWidth)
            }
        },
        onSegmentClick(segment) {
          if(segment.group.trim()) {
              let segmentVal = {
                  name: segment.labelVal,
                  startAt: segment.timeRange[0],
                  endsAt: segment.timeRange[1]
              };
              this.$store.commit("SET_TIMELINE_BEHAVIOR_SELECTION", segmentVal);
          } else {
              this.$store.commit("SET_TIMELINE_BEHAVIOR_SELECTION", null);
          }
        },
        onSelectionChange(zoom) {
          if (zoom) {
            let selectionInterval = {
                startsAt: (zoom[0].getMilliseconds() / 1000) + zoom[0].getSeconds() + zoom[0].getMinutes() * 60 + zoom[0].getHours() * 60 * 60,
                endsAt: (zoom[1].getMilliseconds() / 1000) + zoom[1].getSeconds() + zoom[1].getMinutes() * 60 + zoom[1].getHours() * 60 * 60,
            };
                // For now we have this commented, this code allow calculation of current selected behaviors, so timeline can display it
                // const chartBehaviors = this.$store.state.analyzedVideos.analyzedVideoBehaviour.behavior;
                // const selectedBehaviors = Object.keys(chartBehaviors)
                //     .filter(key => chartBehaviors[key].length > 0)
                //     .map(key => {
                //         const bhv = chartBehaviors[key]
                //             .filter(bh => {
                //                 return (moment(bh.startTime).isSameOrAfter(moment(zoom[0]), 's') && moment(bh.startTime).isSameOrBefore(moment(zoom[1]), 's'))
                //                     || (moment(bh.endTime).isSameOrAfter(moment(zoom[0]), 's') && moment(bh.endTime).isSameOrBefore(moment(zoom[1]), 's'))
                //             })
                //             .map(bh => {
                //                 return {
                //                     startAt: bh.startTime,
                //                     endsAt: bh.endTime
                //                 }
                //         });
                //         return { name: key, values: bhv }
                //     })
                //     .filter( bh => {return bh.values.length > 0});
                this.$store.commit("SET_TIMELINE_INTERVAL_SELECTION", { selectionInterval });
          } else {
              this.$store.commit("SET_TIMELINE_BEHAVIOR_SELECTION", null);
              this.$store.commit("SET_TIMELINE_INTERVAL_SELECTION", null);
          }
        },
        buildChartData(inputData) {

          let chartArray = [];
          if (inputData.events.oscillationEvents.length > 0) {

            chartArray.push({
                group:  'Oscillation ',
                data: [
                    {
                        label: '',
                        data: inputData.behavior.oscillations.map(i => {
                            return {
                                timeRange: [i.startTime, i.endTime],
                                val: i.behaviorLabel,
                                labelVal: i.behaviorType
                            }
                        })
                    }
                ]
            });
            chartArray.push({
                 group: ' ',
                 data: [
                     {label: '',
                         data: inputData.events.oscillationEvents.map(i => {
                             return {
                                 timeRange: [i.startTime, i.endTime],
                                 val: this.getEventLabel(i.eventType)
                             }
                         })
                     }

                 ]
             });
             chartArray.push({
                group: '  ',
                data: [{
                    label: '',
                    data: []
                }]
             });
          }

          if (inputData.events.spikeEvents.length > 0) {
            chartArray.push({
                group: 'Peaks ',
                data: [
                  {label: '',
                    data: inputData.behavior.spikes.map(i => {
                        return {
                            timeRange: [i.startTime, i.endTime],
                            val: i.behaviorLabel,
                            labelVal: i.behaviorType
                        }
                    })
                  }
                ]
            });
            chartArray.push({
                 group: '   ',
                 data: [
                     {label: '',
                         data: inputData.events.spikeEvents.map(i => {
                             return {
                                 timeRange: [i.startTime, i.endTime],
                                 val: this.getEventLabel(i.eventType)
                             }
                         })
                     }

                 ]
             });
             chartArray.push({
                group: '    ',
                data: [{
                    label: '',
                    data: []
                }]
             });
          }

          if (inputData.events.treewellEvents.length > 0) {
            chartArray.push({
                group: 'Valley ',
                data: [
                    {label: '',
                    data: inputData.behavior.treewells.map(i => {
                        return {
                            timeRange: [i.startTime, i.endTime],
                            val: i.behaviorLabel,
                            labelVal: i.behaviorType
                        }
                    })
                  }
                ]
            });
            chartArray.push({
                 group: '     ',
                 data: [
                     {label: '',
                         data: inputData.events.treewellEvents.map(i => {
                             return {
                                 timeRange: [i.startTime, i.endTime],
                                 val: this.getEventLabel(i.eventType)
                             }
                         })
                     }

                 ]
             });
             chartArray.push({
                group: '      ',
                data: [{
                    label: '',
                    data: []
                }]
             });
          }

          if (inputData.events.surgeEvents.length > 0) {
            chartArray.push({
                group:  'Surge ',
                data: [{
                    label: '',
                    data: inputData.behavior.surges.map(i => {
                        return {
                            timeRange: [i.startTime, i.endTime],
                            val: i.behaviorLabel,
                            labelVal: i.behaviorType
                        };
                    })
                }]
            });
            chartArray.push({
                 group: '       ',
                 data: [
                     {label: '',
                         data: inputData.events.surgeEvents.map(i => {
                             return {
                                 timeRange: [i.startTime, i.endTime],
                                 val: this.getEventLabel(i.eventType)
                             }
                         })
                     }

                 ]
             });
             chartArray.push({
                group: '        ',
                data: [{
                    label: '',
                    data: []
                }]
             });
          }

          if (inputData.events.diveEvents.length > 0) {
            chartArray.push({
                group: 'Dive',
                data: [{
                    label: '',
                    data: inputData.behavior.dives.map(i => {
                        return {
                            timeRange: [i.startTime, i.endTime],
                            val: i.behaviorLabel,
                            labelVal: i.behaviorType
                        };
                    })
                }]
            });
            chartArray.push({
                 group: '         ',
                 data: [
                     {label: '',
                         data: inputData.events.diveEvents.map(i => {
                             return {
                                 timeRange: [i.startTime, i.endTime],
                                 val: this.getEventLabel(i.eventType)
                             }
                         })
                     }

                 ]
             });
             chartArray.push({
                group: '          ',
                data: [{
                    label: '',
                    data: []
                }]
             });
          }

          //remove final empty line
          if (chartArray.length > 0) {
            chartArray.pop();
          }

          return chartArray;
      },
        getEventLabel(event) {
            switch (event) {
                case 'traffic_surge': return 'Traffic surge event';
                case 'traffic_dive': return 'Traffic dive event';
                case 'traffic_inc': return 'Traffic increase event';
                case 'traffic_dec': return 'Traffic decrease event';
                default:
                    return 'not known'
            }
        }
    }
  }
</script>

<style lang="scss">
    .grid-background{
        fill: rgba(57, 60, 67, 0.6) !important;
    }
    .selection {

    }
    .timelines-chart .axises .grp-axis text, .timelines-chart .axises .y-axis text, text{
        opacity: 0.5;
    }
    .chart-zoom-selection, .brusher .brush .selection {
        stroke: #0D96FF!important;
        stroke-opacity: 1!important;
        fill-opacity: .7!important;
        fill: #0D96FF!important;
    }
    .brusher .grid line{
        stroke: rgba(57, 60, 67, 0.8) !important;;
    }
    .series-group {
        fill:rgba(57, 60, 67, 0.6) !important;
    }
    text {
        fill: white!important;
    }
    .timelines-chart .axises .y-axis text, .timelines-chart .axises .grp-axis text {
        fill:white!important;
    }

</style>
