<template>
    <div class="potential-aoi-panel">
      <div class="instruction-label">
        Click to load video
      </div>
      <div class="similarity-wrapper">
        <div class="container-wrapper" v-bind:style="[index > 2 && index % 2 === 1 ? {padding: 0} : '']" v-for="(score, index) in similarityData.scores" v-bind:key="score.pipelineName">
          <div class=largeContainer v-if="index < 2" v-b-tooltip="score.pipelineName"  v-on:click="playMatchVideo(score.pipelineId)">
            <span class="hover-container"></span>
            <img :src="score.processedSrc" class="similarityImage"/>
            <div class="higher-match-text">{{ formatPercent(score.score) }}%<br/>MATCH</div>
          </div>
          <div v-if="index >= 2 && index % 2 === 0" class="small-containers-wrapper">
            <div class="smallContainer left-small-container" v-b-tooltip="score.pipelineName" v-on:click="playMatchVideo(score.pipelineId)">
              <span class="hover-container"></span>
              <img :src="score.processedSrc" class="similarityImage"/>
              <div class="higher-match-text">{{ formatPercent(score.score) }}%<br/>MATCH</div>
            </div>
            <div class="smallContainer right-small-container" v-if="similarityData.scores[index + 1]" v-on:click="playMatchVideo(similarityData.scores[index+1].pipelineId)" v-b-tooltip="similarityData.scores[index + 1].pipelineName">
              <span class="hover-container"></span>
              <img :src="similarityData.scores[index + 1].processedSrc" class="similarityImage" />
              <div class="higher-match-text">{{ formatPercent(similarityData.scores[index+1].score) }}%<br/>MATCH</div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: "SimilarityPanel",
    data() {
        return {};
    },
    mounted() {

    },
    computed: {
      similarityData() {
        let simArray = this.$store.state.similarity.data;
        if (!simArray) simArray = [];
        if (simArray.scores) {
            simArray.scores = simArray.scores.sort((a, b) => (b.score > a.score) ? 1 : -1).splice(0, 10)
        }
        return simArray;
      }
    },
    methods: {
      playMatchVideo(pipelineId) {
        this.$store.dispatch('getAndPlayAnalyzedVideo', pipelineId);
      },
      formatPercent(num) {
        return num = (num * 100).toFixed(1);
      }
    }
}
</script>

<style lang="scss">
.potential-aoi-panel {
  width: 250px;
  display: flex;
  flex-direction: column;

    .instruction-label {
        margin-left: 5px;
        width: 240px;
        font-size: 11px;
        padding: 2px;
        opacity: 1.0;
        color: #d6e3ed;
        border-color: #d6e3ed;
        border-style: solid;
        border-left: 1px;
        border-right: 1px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

  .similarity-wrapper {

    height: 340px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    .container-wrapper{
      padding: 5px;
      .small-containers-wrapper{
        display: flex;
        flex-direction: row;
        .left-small-container{
          padding-right: 5px;
        }
        .right-small-container{
          padding-left: 5px;
        }
      }
      .higher-match-text {
        position: absolute;
        text-align: center;
        background-color: rgba(57, 60, 67, 0.6);
      }
      .hover-container {
          width: 100%;
          height: 100%;
          position: absolute;
          background-color: rgba(33, 44, 56, 0.2);
          display: none;
      }
      .largeContainer {
        cursor: pointer;
        text-align: center;
        position: relative;
        &:hover {
          .hover-container {
            display: block;
          }
        }
        .similarityImage {
          width: 100%;
        }
        .higher-match-text {
          margin-top: -90px;
          margin-bottom: 32px;
          margin-left: 70px;
          width: 80px;
        }
      }
      .smallContainer {
        cursor: pointer;
        position: relative;
        text-align: center;
        height: 60px;
        width: 50%;
        &:hover {
          .hover-container {
            display: block;
          }
        }
        .similarityImage {
          height: 100%;
          width: 100%;
        }
        .higher-match-text {

          text-align: center;
          margin-top: -40px;
          margin-bottom: 13px;
          font-size: 11px;
          width: 50px;
          margin-left: 25px;
        }
      }
    }

  }
}

</style>
