<template>
    <div class="graph">
        <div class="main-chart"></div>
    </div>
</template>

<script>
    import * as d3 from "d3";
    import * as moment from 'moment'
    export default {
        name: "vue-line-chart",
        props: {
          chartHeight: null,
          chartWidth: null,
        },
        data() {
            return {
                width: 400,
                height: 200,
                navHeight: 50,
                yAxisWidth: 26,
                duration: null,
                scales: null,
                svg: null,
                data: this.values,
                currentVideoTime: 0,
                animationStarted: false,
                path: null,
                //Translate co-ords for chart, x axis and yaxis. This is injected into template
                stoppedAt: null,
                limit: 10,
                dataUpdateInterval: 33.33, // This is basically the duration of one frame
                videoDuration: 50, //video duration in seconds
                startIndex: 0,
                target: {},
                navTickRatio: 0,
                line: null,
                tooltip: null,
                xAxisSvg: null,
                yAxisSvg: null,
                x: null,
                y: null,
                navChart: null,
                area: null,
                areaPath: null,
                selectedArea: null,
                selectedAreaPath: null,
                selectedLine: null,
                selectedPath: null,
                viewPort: null,
                lineTransitionFinished: false,
                navTransitionFinished: false,
                circleTransitionFinished: false,
                domainTransitionFinished: false,
                behaviors: null,
                tickLoose: 0,
                navXScale: null,

                behaviorColors: [
                    [
                        {offset: "0%", color: "rgba(244, 80, 195, 0.1)"},
                        {offset: "20%", color: "rgba(244, 80, 195, 0.2)"},
                        {offset: "40%", color: "rgba(244, 80, 195, 0.3)"},
                        {offset: "60%", color: "rgba(244, 80, 195, 0.4)"},
                        {offset: "80%", color: "rgba(244, 80, 195, 0.5)"},
                    ],
                    [
                        {offset: "0%", color: "rgba(157, 80, 239, 0.1)"},
                        {offset: "20%", color: "rgba(157, 80, 239, 0.2)"},
                        {offset: "40%", color: "rgba(157, 80, 239, 0.3)"},
                        {offset: "60%", color: "rgba(157, 80, 239, 0.4)"},
                        {offset: "80%", color: "rgba(157, 80, 239, 0.5)"},
                    ],
                    [
                        {offset: "0%", color: "rgba(99, 86, 239, 0.1)"},
                        {offset: "20%", color: "rgba(99, 86, 239, 0.2)"},
                        {offset: "40%", color: "rgba(99, 86, 239, 0.3)"},
                        {offset: "60%", color: "rgba(99, 86, 239, 0.4)"},
                        {offset: "80%", color: "rgba(99, 86, 239, 0.5)"},
                    ],
                    [
                        {offset: "0%", color: "rgba(251, 124, 74, 0.1)"},
                        {offset: "20%", color: "rgba(251, 124, 74, 0.2)"},
                        {offset: "40%", color: "rgba(251, 124, 74, 0.3)"},
                        {offset: "60%", color: "rgba(251, 124, 74, 0.4)"},
                        {offset: "80%", color: "rgba(251, 124, 74, 0.5)"},
                    ],
                    [
                        {offset: "0%", color: "rgba(255, 82, 111, 0.1)"},
                        {offset: "20%", color: "rgba(255, 82, 111, 0.2)"},
                        {offset: "40%", color: "rgba(255, 82, 111, 0.3)"},
                        {offset: "60%", color: "rgba(255, 82, 111, 0.4)"},
                        {offset: "80%", color: "rgba(255, 82, 111, 0.5)"},
                    ]
                ],

                colors: [
                    {
                        index: 0,
                        name: 'oscillation',
                        id: 'oscillations-area-gradient',
                        class: 'oscillations-area',
                    },
                    {
                       index: 1,
                        name: 'spike', class: 'spikes-area',
                        id: 'spikes-area-gradient',
                    },
                    {
                        index: 2,
                        name: 'anti_spike',
                        id: 'treewells-area-gradient',
                        class: 'treewells-area',
                    },
                    {
                        index: 3,
                        name: 'surge', class: 'surges-area',
                        id: 'surges-area-gradient',
                    },
                    {
                        index: 4,
                        name: 'dive', class: 'dives-area',
                        id: 'dives-area-gradient',
                    }
                ]
            }
        },
        computed: {
            values() {
                return this.$store.state.linechart.values;
            },
            isPlaying() {
                return this.$store.state.videoPlayer.isPlaying;
            },
            timelineSelection() {
                return this.$store.state.linechart.timelineBrushSelection;
            },
            currentVideoTimeC() {
                return this.$store.state.videoPlayer.currentTime
            },
            behaviorSelected() {
                return this.$store.state.linechart.timelineSelectedBehavior;
            }
        },

        watch: {
            currentVideoTimeC(val) {
                this.duration = this.$store.state.videoPlayer.currentTime;
                this.startIndex = this.duration;
            },
            values(val) {
                this.data = val;
                this.duration = this.$store.state.videoPlayer.duration;
                this.animationStarted = false;
                if(this.data && this.data.length > 0) {
                    let d3chart = d3.select('.main-chart');
                    Array.from(d3chart.node().children).forEach(node => node.remove());
                    d3.select('.navigator').node().remove();
                    this.initAndStart();
                }
            },
            isPlaying(val) {
                if(this.data){
                    if( val ) {
                        if(this.$store.state.videoPlayer.currentTime < 1){
                            this.start();
                        } else {
                            this.resume();
                        }
                    } else {
                            this.stop();
                    }
                }
            },
            behaviorSelected(val) {
                if(val) {
                    let behaviorsData = this.$store.state.analyzedVideos.analyzedVideoBehaviour.behavior;
                    let data = Object.keys(behaviorsData).filter(bh => behaviorsData[bh].length > 0).map(a => {return a.slice(0, a.length - 1)});
                    d3.selectAll('.behavior-gradient').nodes().forEach(node => {
                        node.remove();
                    });
                    if(this.behaviors) {
                        this.behaviors.forEach(behaviorType => {
                            behaviorType.svg.node().remove()
                        })
                    }
                    this.behaviors = [];
                    let bhValues = val;
                    let startTimeInSeconds = bhValues.startAt.getMilliseconds() / 1000 + bhValues.startAt.getSeconds() + bhValues.startAt.getMinutes() * 60 + bhValues.startAt.getHours() * 3600;
                    let endTimeInSeconds = bhValues.endsAt.getMilliseconds() / 1000 + bhValues.endsAt.getSeconds() + bhValues.endsAt.getMinutes() * 60 + bhValues.endsAt.getHours() * 3600;
                    bhValues.data = this.target.data.filter(a => a.second >= startTimeInSeconds && a.second <= endTimeInSeconds);
                    let colorValue = this.colors.find(a => a.name.toLowerCase().trim() === val.name.toLowerCase().trim());

                    let clsGradient = 'behavior-area';
                    if(colorValue) {
                        let colorGradient = this.behaviorColors[colorValue.index];
                        clsGradient = colorValue.class;
                        this.generateGradient(bhValues.data, colorGradient, colorValue.id, true);

                    }
                    bhValues.svg = this.svg.append("path")
                                .data([bhValues.data])
                                .attr('class', clsGradient)
                                .attr("d", this.area);
                    this.behaviors = [ bhValues ];

                    d3.selectAll('#focus-text').remove();
                    d3.selectAll('#focus-rect').remove();
                    this.appendFocusRect();


                } else {
                    d3.selectAll('.behavior-gradient').nodes().forEach(node => {
                        node.remove();
                    });
                    if(this.behaviors) {
                        this.behaviors.forEach(behaviorType => {
                            behaviorType.svg.node().remove()
                        })
                    }
                    this.behaviors = [];
                }
            },
            timelineSelection(val) {
                if(this.data) {
                    if(val) {
                        // For the moment, we have the code commented, it allows us to draw selected behaviors from timeline
                        // d3.selectAll('.behavior-gradient').nodes().remove();
                        // if(this.behaviors) {
                        //     this.behaviors.forEach(behaviorType => {
                        //         behaviorType.values.forEach(bh => {
                        //             bh.svg.node().remove()
                        //         })
                        //     })
                        // }
                        // let selectedBehaviors = val.selectedBehaviors;
                        // selectedBehaviors.map(bh => {
                        //     bh.values = bh.values.map((bhValues) => {
                        //         let startTimeInSeconds = bhValues.startAt.getMilliseconds() / 1000 + bhValues.startAt.getSeconds() + bhValues.startAt.getMinutes() * 60 + bhValues.startAt.getHours() * 3600;
                        //         let endTimeInSeconds = bhValues.endsAt.getMilliseconds() / 1000 + bhValues.endsAt.getSeconds() + bhValues.endsAt.getMinutes() * 60 + bhValues.endsAt.getHours() * 3600;
                        //         bhValues.data = this.target.data.filter(a => a.second >= startTimeInSeconds && a.second <= endTimeInSeconds);
                        //         let colorValue = this.colors.find(a => a.name.toLowerCase().trim() === bh.name.toLowerCase().trim());
                        //         let clsGradient = 'behavior-area';
                        //         if(colorValue) {
                        //             clsGradient = colorValue.class;
                        //             this.generateGradient(bhValues.data, colorValue.colors, colorValue.id, true);
                        //
                        //         }
                        //         bhValues.svg = this.svg.append("path")
                        //                     .data([bhValues.data])
                        //                     .attr('class', clsGradient)
                        //                     .attr("d", this.area);
                        //
                        //         return bhValues;
                        //     });
                        //     return bh;
                        // });
                        // this.behaviors = selectedBehaviors;

                        let selectedData = this.target.data.filter(a => a.second >= val.selectionInterval.startsAt && a.second <= val.selectionInterval.endsAt);

                        this.selectedAreaPath.data([selectedData])
                            .attr("d", this.area);
                        this.selectedPath
                            .attr('d', this.selectedLine(selectedData));

                    } else {
                        // For now this code is commented, it allows us to draw/remove selected behaviors from timeline chart
                        // d3.selectAll('.behavior-gradient').nodes().remove();
                        // if(this.behaviors) {
                        //     this.behaviors.forEach(behaviorType => {
                        //         behaviorType.values.forEach(bh => {
                        //             bh.svg.node().remove()
                        //         })
                        //     })
                        // }
                        // this.behaviors = [];

                        this.selectedPath
                            .attr('d', this.selectedLine([]));
                        this.selectedAreaPath.data([[]])
                            .attr("d", this.area);
                    }
                }
            },
        },

        mounted() {
            this.initAndStart();
        },
        methods: {
            initAndStart() {
                this.height = this.chartHeight - this.navHeight*2;
                this.width = this.chartWidth - this.yAxisWidth;
                this.data = this.$store.state.linechart.values;
                this.videoDuration = this.$store.state.videoPlayer.duration;
                this.target = {data: this.data};
                this.createMainChart();
                this.createBrushChart();
            },

            moveBehaviors() {
                // for the moment we have just one behavior,
                // uncomment this and do further changes of behavior object if we'll have again multiples behaviors
                // if(this.behaviors) {
                //     this.behaviors.forEach(behaviorType => {
                //         behaviorType.values.forEach(bh => {
                //             bh.svg.attr('d', this.area);
                //         })
                //     })
                // }

                // code used for just one behavior, check above comment if multiples behaviors we'll be added
                if(this.behaviors) {
                    this.behaviors.forEach(behaviorType => {
                        behaviorType.svg.attr('d', this.area);
                    })
                }
            },

            generateGradient(data,colorData, id, isBehaviorGradient) {
                this.svg.append("linearGradient")
                    .attr("id", id)
                    .attr('class', isBehaviorGradient ? 'behavior-gradient' : '')
                    .attr("gradientUnits", "userSpaceOnUse")
                    .attr("x1", 0).attr("y1", 0 )
                    .attr("x2", 0).attr("y2", 50)
                    .selectAll("stop")
                    .data(colorData)
                    .enter()
                    .append("stop")
                    .attr("offset", function(d) { return d.offset; })
                    .attr("stop-color", function(d) { return d.color; });
            },

            createMainChart() {
                let start = new Date();
                start.setHours(0, 0, this.startIndex, 0);
                let end = new Date();
                end.setHours(0, 0, this.startIndex + this.limit, 0);

                let xDomain = [start, end];
                // Now we define the X and Y scales.
                this.x = d3
                    .scaleTime()
                    .domain(xDomain)
                    .range([0, this.width]);

                let max = Math.ceil(d3.max(this.target.data, (d) => {return d.count;}));
                let min = Math.floor(d3.min(this.target.data, (d) => {return d.count;}));
                let ticks = 4;
                if( max < 4 ) {
                    ticks = max;
                }
                const y = this.y = d3
                    .scaleLinear()
                    .domain([min, max])
                    .range([this.height, 0]);

                // Define x and y axis
                const xAxis = d3
                    .axisBottom()
                    .scale(this.x)
                    .tickFormat(d3.timeFormat("%M:%S"))
                    .ticks(10);

                const yAxis = d3
                    .axisLeft()
                    .scale(y)
                    .tickFormat(d3.format("d"))
                    .ticks(ticks);

                //define area
                this.area = d3.area()
                    .x( d => {let dd = new Date(); dd.setHours(0, 0, 0, d.day*1000);  return this.x(dd); })
                    .y0(this.height)
                    .y1(d => { return y(d.count); });

                // Define line
                this.line = d3.line()
                    .x( d => {let dd = new Date(); dd.setHours(0, 0, 0, d.day*1000);  return this.x(dd); })
                    .y(d => {return y(d.count)});

                // Append svg that will contain linechart, and set size
                const ySvg = d3.select('.main-chart')
                    .append('svg')
                    .attr('width', 26)
                    .style('overflow', 'initial')
                    .attr('height', this.height + 50);

                const mainChartDivWrapper = d3.select('.main-chart')
                .append('div');

                this.svg = mainChartDivWrapper
                    .append('svg')
                    .attr('class', 'chart')
                    .attr('width', this.width + 10)
                    .attr('height', this.height);

                this.yAxisSvg = ySvg.append('g')
                    .attr('class', 'y axis')
                    .attr('transform', 'translate(25, 0)')
                    .call(y.axis = yAxis);

                let colorData = [
                    {offset: "0%", color: "rgba(113, 125, 137, 0.1)"},
                    {offset: "20%", color: "rgba(113, 125, 137, 0.2)"},
                    {offset: "40%", color: "rgba(113, 125, 137, 0.3)"},
                    {offset: "60%", color: "rgba(113, 125, 137, 0.4)"},
                    {offset: "80%", color: "rgba(113, 125, 137, 0.5)"},
                ];
                this.generateGradient(this.target.data, colorData, 'area-gradient');
                this.areaPath = this.svg.append("path")
                    .data([this.target.data])
                    .attr("class", "area")
                    .attr("d", this.area);

                this.selectedAreaPath = this.svg.append("path")
                    .data([[]])
                    .style('fill', 'rgba(255, 223, 60, 0.5)')
                    .attr("class", "area-selected")
                    .attr("d", this.area);

                const paths = this.svg.append('g')
                    .attr('id', 'path-g');

                // Append svg that will contain linechart, and set size
                const xSvg = mainChartDivWrapper
                    .append('svg')
                    .attr('width', this.width)
                    .style('overflow', 'initial')
                    .attr('height', 50);

                //Append svg with X and Y axis
                this.xAxisSvg = xSvg.append('g')
                    .attr('class', 'x axis x-axis')
                    .call(this.x.axis = xAxis);

                this.path = paths.append('path')
                        .data([this.target.data])
                        .attr('class', name + ' group')
                        .style('stroke-width', 3)
                        .style('stroke', '#717D89');

                this.path.attr('d', this.line);
                this.areaPath.attr('d', this.area);
                this.appendFocusRect();

            },

            /**
             * Append rectangle to show tooltip with count and duration
             */
            appendFocusRect() {
                // Create the text that travels along the curve of chart
                let focusText = this.svg
                    .append('g')
                    .append('text')
                    .style("opacity", 0)
                    .attr('id', 'focus-text')
                    .attr("text-anchor", "left")
                    .attr("alignment-baseline", "middle");

                let bisect = d3.bisector(function(d) { return d.day; }).left;

                let rect = this.svg
                    .append('rect');

                rect.style("fill", "none")
                    .style("pointer-events", "all")
                    .attr('width', this.width)
                    .attr('id', 'focus-rect')
                    .attr('height', this.height + 10)
                    .on('click', () => {
                        const coords = d3.mouse(this.svg.node());
                        const newData= {
                            x: this.x.invert(coords[0]),
                            y: this.y.invert(coords[1])
                        };
                        let sec = newData.x.getMilliseconds() / 1000 + newData.x.getSeconds() + newData.x.getMinutes() * 60 + newData.x.getHours() * 60 * 60;
                        this.$store.commit('CHANGE_CURRENT_VIDEO_TIME', sec);
                    })
                    .on('mouseover', () => {
                        focusText.style("opacity",1)
                    })
                    .on('mousemove', () => {
                        const coords = d3.mouse(rect.node());
                        const newData= {
                            x: this.x.invert(coords[0]),
                            y: this.y.invert(coords[1])
                        };
                        const x0 = newData.x.getMilliseconds() / 1000 + newData.x.getSeconds() + newData.x.getMinutes() * 60 + newData.x.getHours() * 60 * 60;

                        const i = bisect(this.target.data, x0, 1);
                        const selectedData = this.target.data[i];

                        if(selectedData){
                            const d = new Date();
                            d.setHours(0, 0, 0, 0);
                            d.setMilliseconds(selectedData.day * 1000);
                            let format = d.getHours() > 0 ? 'hh:mm:ss' : 'mm:ss';
                            focusText
                                .html(`Time: ${moment(d).format(format)}.${d.getMilliseconds()} - Objects: ${Math.round(selectedData.count)}`)
                                .attr("x", 350)
                                .attr("y", 10)
                        }
                    })
                    .on('mouseout', () => {
                        focusText.style("opacity", 0)
                    });
            },

            /**
             * Create navigation chart
             */
            createBrushChart() {
                // we start by setting up the drawing area.
                this.navChart = d3.select('.graph').append('svg')
                    .classed('navigator', true)
                    .attr('width', this.width + 20 )
                    .attr('height', this.navHeight * 2)
                    .attr('transform', 'translate(25, 0)')
                    .append('g');

                // Now we define the X and Y scales.
                let start = new Date();
                start.setHours(0, 0, 0, 0);
                let end = new Date();
                end.setHours(0, 0, 0, this.videoDuration * 1000);
                let xDomain = [start, end];
                const navXScale = this.navXScale = d3.scaleTime()
                    .domain(xDomain)
                    .range([0, this.width - 25]);

                let max = d3.max(this.target.data, function(d) {return d.count;}) + 1;
                let min = d3.min(this.target.data, function(d) {return d.count;});

                const navYScale = d3.scaleLinear()
                    .domain([min, max])
                    .range([50, 0]);

                // For the navigation chart, we only want an X axis, so we define and add that now.
                const navXAxis = d3.axisBottom()
                    .scale(navXScale)
                    .tickFormat(d3.timeFormat("%M:%S"))
                    .ticks(6);

                this.navChart.append('g')
                    .attr('class', 'x axis')
                    .attr('id', 'nav-x-axis')
                    .attr('transform', 'translate(20,' + this.navHeight + ')')
                    .call(navXAxis);

                // Add data to navigation chart
                const navData = d3.area()
                    .x( d => {let dd = new Date(); dd.setHours(0, 0, 0, d.day*1000);  return navXScale(dd); })
                    .y0(this.navHeight)
                    .y1((d) => { return navYScale(d.count)});

                const navLine = this.selectedLine = d3.line()
                    .x( d => {let dd = new Date(); dd.setHours(0, 0, 0, d.day*1000);  return navXScale(dd); })
                    .y(function (d) { return navYScale(d.count)});

                this.navChart.append('path')
                    .attr('class', 'data')
                    .attr('transform', 'translate(20,0)')
                    .attr('d', navData(this.data));

                this.navChart.append('path')
                    .attr('class', 'line')
                    .attr('transform', 'translate(20,0)')
                    .attr('d', navLine(this.data));

                this.selectedPath = this.navChart.append('path')
                    .attr('class', 'selected-line')
                    .attr('transform', 'translate(20,0)')
                    .style('fill', 'none')
                    .style('stroke-width', 3)
                    .style('stroke', 'rgb(255, 223, 60)');

                let tenSecondsViewInterval = new Date();
                tenSecondsViewInterval.setHours(0, 0, 1, 0);
                this.navTickRatio = navXScale(tenSecondsViewInterval) * 10;
                if(this.navTickRatio < 0 || this.navTickRatio > this.width) {
                    // TODO remove magic numbers (we should have a default brush size)
                    this.navTickRatio = 200
                }

                // Add brush
                this.viewport = d3.brushX()
                    .handleSize(this.width)
                    .extent([[0, 0], [this.width, this.navHeight]]);

                let vp = this.navChart.append("g");

                vp.attr("class", "viewport")
                    .call(this.viewport)
                    .call(this.viewport.move, [0, this.navTickRatio])
                    .selectAll("rect")
                    .attr('transform', 'translate(20,0)')
                    .attr("height", this.navHeight);

                this.navChart.selectAll('.overlay')
                    .attr('cursor', 'initial')
                    .attr('pointer-events', 'none');

                this.navChart.selectAll(".handle").remove();

                this.viewport
                    .on('start', () => {
                        if(d3.event.sourceEvent !== null && d3.event.sourceEvent.type === 'mousedown'){
                            this.stop();
                            this.stopPlayer();
                        }
                        this.navChart.selectAll(".handle").remove()
                    })
                    .on('end', () => {
                        if(d3.event.sourceEvent !== null && d3.event.sourceEvent.type === 'mouseup'){
                            let startTime = navXScale.invert(d3.event.selection[0]);
                            this.startIndex = startTime.getMilliseconds()/1000 + startTime.getSeconds() + startTime.getHours() * 60 * 60 + startTime.getMinutes() * 60;
                            this.$store.commit('CHANGE_CURRENT_VIDEO_TIME', this.startIndex);
                            let start = new Date();
                            start.setHours(0, 0, 0);
                            start.setMilliseconds(this.startIndex * 1000);
                            let end = new Date();
                            end.setHours(0, 0, 0);
                            end.setMilliseconds((this.startIndex + this.limit) * 1000);
                            this.x.domain([start, end]);
                            this.path.attr('d', this.line);
                            this.areaPath.attr('d', this.area);
                            this.selectedAreaPath.attr('d', this.area);
                            this.moveBehaviors();
                            this.forceDomain();
                            this.navChart.selectAll(".handle").remove();
                        }
                    });
            },

            tick() {
                if(this.startIndex >= this.videoDuration || this.stopped) {
                    return;
                }
                this.animationStarted = true;

                // Add new values
                this.path.attr('d', this.line);
                this.areaPath.attr('d', this.area);
                this.selectedAreaPath.attr('d', this.area);
                this.moveBehaviors();
                this.startIndex = this.startIndex + ( this.dataUpdateInterval / 1000 );

                // Slide x-axis left
                let start = new Date();
                start.setHours(0, 0, 0);
                start.setMilliseconds(this.startIndex * 1000);
                let end = new Date();
                end.setHours(0, 0, 0);
                end.setMilliseconds((this.startIndex + this.limit) * 1000 );
                this.x.domain([start, end]);
                this.navChart
                    .call(this.viewport)
                    .call(this.viewport.move, [this.startIndex * this.navTickRatio / 10,  this.navTickRatio + (this.startIndex * this.navTickRatio / 10)]);
                this.navChart.selectAll(".handle").remove();

                this.forceDomain();
                setTimeout(() => this.tick(), this.dataUpdateInterval)
            },

            forceDomain() {
                this.xAxisSvg.call(this.x.axis)
            },

            stop() {
                this.stopped = true;
            },
            stopPlayer() {
                this.$store.commit('PAUSE_VIDEO_EXTERNAL', false)
            },
            startPlayer() {
                this.$store.commit('PAUSE_VIDEO_EXTERNAL', true)
            },

            start() {
                this.startIndex = 0;
                this.stopped = false;
                this.tick()
            },

            resume() {
                this.stopped = false;
                this.tick()
            }
        }
    };
</script>
<style lang="scss">
    .area {
        fill: url(#area-gradient);
        stroke-width: 0;
    }
    .oscillations-area {
        fill: url(#oscillations-area-gradient);
        stroke-width: 0;
    }
    .surges-area {
        fill: url(#surges-area-gradient);
        stroke-width: 0;
    }
    .dives-area {
        fill: url(#dives-area-gradient);
        stroke-width: 0;
    }
    .spikes-area {
        fill: url(#spikes-area-gradient);
        stroke-width: 0;
    }
    .treewells-area{
        fill: url(#treewells-area-gradient);
        stroke-width: 0;
    }

    .graph {
        .main-chart {
            display: flex;
            flex-direction: row;
        }

        display: flex;
        flex-direction: column;
        .axis {
            stroke-width: 1;
            .tick line {
                stroke: black;
            }
            .tick text {
                fill: black;
                font-size: 1em;
            }

            .domain {
                fill: none;
                stroke: black;
            }
        }

        .group {
            fill: none;
            stroke: black;
            stroke-width: 1.5;
        }

        .navigator {
            .data {
                fill: none;
            }
            .line {
                fill: none;
                stroke: #3a76b2;
                stroke-width: 1px;
            }
            .extent {
                fill: currentColor;
                opacity: 0.3;
            }
        }

    }
</style>
