<template>
    <div class="anomaly-panel">
      <vue-previewer  :images="$store.state.videoPlayer.video.isOriginalPlayed ? [] : anomalyData" mode="image" :options="options">
        <template v-slot:footer="{image}">{{image.name}}</template>
      </vue-previewer>
    </div>
</template>

<script>
import VuePreviewer from 'vue-image-previewer'
export default {
    name: "AnomalyPanel",
    components: {
      VuePreviewer
    },
    data() {
        return {
          options: {
            defaultWidth: '100%',
            thumbnailStyle: {
              width: '190px',
              height: '100px'
            },
            showFooter: true
          }
        };
    },
    mounted() {
    },
    computed: {
      anomalyData() {
        let anomalyRawData = this.$store.state.anomaly.data;
        if (!anomalyRawData.analyzeId) {
          return [];
        }
        let anomalyArray = [];
        anomalyArray.push(this.determineImageSource(anomalyRawData.clustersInfo.allPathsImgUrl, 'All Paths', anomalyRawData.clustersInfo.clustersCount));
        anomalyArray.push(this.determineImageSource(anomalyRawData.clustersInfo.averagePathsImgUrl, 'Average Paths', anomalyRawData.clustersInfo.clustersCount));
        anomalyArray.push(this.determineImageSource(anomalyRawData.outlierData.outliersSummaryImgUrl, 'Outliers', anomalyRawData.outlierData.outliersCount));
        return anomalyArray;
      }
    },
    methods: {
      determineImageSource(imgUrl, dataName, count) {
        //TODO: Possibly handle the case where there is not value (no-thumbnail.png)
        let infoString = 'Anomaly Detection: ' + dataName;
        if (dataName == 'Outliers') { infoString += ', # of Outliers: ' + count; }
        else { infoString += ', Cluster Count: ' + count; }
        let imgObject = {};
        if(this.$store.state.azureBlobData.data.sas.key && this.$store.state.videoPlayer.video.container === this.$store.state.azureBlobData.data.sas.container)
         imgObject = {
          src: `https://${this.$store.state.azureBlobData.data.blobDetails.storageName}.blob.core.windows.net/${this.$store.state.videoPlayer.video.container}/${imgUrl}?${this.$store.state.azureBlobData.data.sas.key}&t=${new Date().getTime()}`,
          thumbnailSrc: `https://${this.$store.state.azureBlobData.data.blobDetails.storageName}.blob.core.windows.net/${this.$store.state.videoPlayer.video.container}/${imgUrl}?${this.$store.state.azureBlobData.data.sas.key}&t=${new Date().getTime()}`,
          infoString: infoString,
          name: infoString
        };
        return imgObject;
      }
    }
}
</script>

<style>
.content {
  opacity: 1.0 !important;
}
.anomaly-panel {
  width: 600px;
  height: 150px;
  background:rgba(40, 40, 40, 1.0);
  padding: 3px;
  padding-top: 8px;
}
.aoi-label {
  color: #333;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 5px;
}
.next-line {
  clear: both;
}
.anomaly-thumbnail {
  margin-left: 5px;
  width: 193px;
  height: 110px;
  border: 1px solid #333;
  margin-bottom: 5px;
  opacity: 1.0;
}
.anomaly-thumbnail:hover {
  cursor: pointer;
}
.pre__footer {
  color: #555 !important;
}
</style>
